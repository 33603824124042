import { useEffect } from 'react';
import moment from 'moment';
import { Box, Typography } from '@mui/material';
import { usePages } from '../hooks/usePages';

const Countdown = () => {
  const { classes, remainingTime, formatTime, targetDate, setRemainingTime } = usePages()


  useEffect(() => {
    const interval = setInterval(() => {
      const newNow = moment();
      const newRemainingTime = moment.duration(targetDate.diff(newNow));
      setRemainingTime(newRemainingTime);
    }, 60000); 

    return () => {
      clearInterval(interval);
    };
  }, []);



  return (
    <Box className={classes.timeContent}>
    <Box className={classes.time}>
      <Typography className={classes.number} variant="h3">
        {formatTime(remainingTime.days())}
      </Typography>
      <Typography className={classes.text} variant="body2">
        Days
      </Typography>
    </Box>
    <Box className={classes.time}>
      <Typography className={classes.number} variant="h3">
        {formatTime(remainingTime.hours())}
      </Typography>
      <Typography className={classes.text} variant="body2">
        Hours
      </Typography>
    </Box>
    <Box className={classes.time}>
      <Typography className={classes.number} variant="h3">
        {formatTime(remainingTime.minutes())}
      </Typography>
      <Typography className={classes.text} variant="body2">
        Minutes
      </Typography>
    </Box>
    <Box className={classes.time}>
      <Typography className={classes.number} variant="h3">
        {formatTime(remainingTime.seconds())}
      </Typography>
      <Typography className={classes.text} variant="body2">
        Seconds
      </Typography>
    </Box>


  </Box>
  
  );
}

export default Countdown;
