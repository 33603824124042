import { Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { usePages } from '../pages/hooks/usePages';


interface WordAnimationProps {
  text: string;
}

const WordAnimation: React.FC<WordAnimationProps> = ({ text }) => {
  const { classes } = usePages()
  const [animatedText, setAnimatedText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    if (currentIndex < text.length) {
      const timeoutId = setTimeout(() => {
        setAnimatedText((prevText) => prevText + text[currentIndex]);
        setCurrentIndex((prevIndex) => prevIndex + 1);
      }, 50);
      return () => clearTimeout(timeoutId);
    }
  }, [currentIndex, text, text.length]);

  return (
    <Typography className={classes.header} variant="h4">
      {animatedText}
    </Typography>
  );
};


export default WordAnimation;
