import { FunctionComponent } from 'react';
import { Alert, Snackbar } from '@mui/material';

interface IToasterProps {
    setShowToaster: Function;
    showToaster: boolean;
    severity: 'success' | 'error' | 'warning' | 'info';
    message: string;
}

const DefaultToaster: FunctionComponent<IToasterProps> = ({
    setShowToaster,
    showToaster,
    severity,
    message
}) => {
    const handleClose = () => {
        setShowToaster(false);
    };

    return (
        <Snackbar
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={showToaster}
            autoHideDuration={5000}
            onClose={handleClose}
            style={{ position: 'fixed' }}
        >
            <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
                {message}
            </Alert>
        </Snackbar>
    );
};

export default DefaultToaster;
