import { CircularProgress } from "@mui/material";
import { Box } from "@mui/system";

const PreLoader = () => {
    return (
        <Box 
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',    
                height: '50vh'          
            }}
        >
            <CircularProgress color="inherit" disableShrink />
        </Box>
    );
}

export default PreLoader;
