import { FormEvent, SetStateAction, useEffect, useState } from "react";
import useStyles from "../../styles/useStyles"
import { useNavigate } from "react-router-dom";
import moment, { Duration } from 'moment';
import validator from "validator";
import { useMediaQuery, useTheme } from "@mui/material";

export const usePages = () => {
    const theme = useTheme();
    const classes = useStyles();
    const navigate = useNavigate();
    const [showToaster, setShowToaster] = useState(false);
    const [userEmail, setUserEmail] = useState('');
    const [toasterMessage, setToasterMessage] = useState({ severity: '', message: '' });

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isAgreed, setIsAgreed] = useState(false);
    const isSmScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const handleCheckboxChange = (event: { target: { checked: boolean | ((prevState: boolean) => boolean); }; }) => {
        setIsAgreed(event.target.checked);
    };
    const handleModalOpen = () => {
        setIsModalOpen(true);
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
    };
    const clearForm = () => {
        setUserEmail('')
    }

      // Get the current time
  const now = moment();

  // Set your target publication date
  const targetDate = moment('2023-12-24');

  // Calculate the difference in time from now to your target date
  const initialRemainingTime = moment.duration(targetDate.diff(now));

  // Use the initial time difference to set the initial state
  const [remainingTime, setRemainingTime] = useState<Duration>(initialRemainingTime);

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        try {
            const data = new FormData(event.currentTarget);
            const userData = {
                email: data.get('email') as string,
                firstName: data.get('firstName') as string,
                lastName: data.get('lastName') as string,
                message: data.get('message') as string
            }
            if (!userData?.firstName || !userData?.lastName || !userData?.email) throw new Error("Some fields are empty")
            if (!userData.email) {
                throw new Error("Please enter an email address");
            } else if (!validator.isEmail(userData?.email)) { throw new Error("Enter a valid email address") } else {
                setUserEmail(userData?.email)
                clearForm()

                setToasterMessage({ ...toasterMessage, message: 'You have been subscribed', severity: 'success' })
                setTimeout(() => {
                    clearForm()
                    navigate(0)
                }, 3000)
            }

        } catch (error) {
            if (typeof error === "string") {
                setToasterMessage({ ...toasterMessage, message: error, severity: 'error' });
            } else if (error instanceof Error) {
                setToasterMessage({ ...toasterMessage, message: error.message, severity: 'error' });
            } else {
                setToasterMessage({ ...toasterMessage, message: 'An error occurred.', severity: 'error' });
            }

        }

        setShowToaster(true);
      
    }
    const handleEmailChange = (e: { target: { value: SetStateAction<string>; }; }) => {
        setUserEmail(e.target.value);
    };


    const formatTime = (value: number) => {
        return value.toString().padStart(2, '0');
    };

    const formatDuration = (duration: Duration): string => {
        const hours = formatTime(duration.hours());
        const minutes = formatTime(duration.minutes());
        const seconds = formatTime(duration.seconds());
        return `${hours}:${minutes}:${seconds}`;
    };

    useEffect(() => {
        const interval = setInterval(() => {
            setRemainingTime((prevRemainingTime) => {
                const diff = prevRemainingTime.asSeconds() - 1;

                if (diff <= 0) {
                    clearInterval(interval);
                    return moment.duration(0);
                } else {
                    return moment.duration(diff, 'seconds');
                }
            });
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    return {
        classes,
        showToaster, setShowToaster,
        toasterMessage, setToasterMessage,
        handleSubmit,
        navigate, remainingTime, formatTime, userEmail, handleEmailChange,
        isModalOpen, setIsModalOpen, handleModalClose, handleModalOpen, isAgreed, setIsAgreed, handleCheckboxChange,
        isSmScreen,
        targetDate,
        setRemainingTime
    } as const
}