import React from "react";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
  CssBaseline,
  Paper,
} from "@mui/material";
import { LockOutlined, ArrowBack } from "@mui/icons-material";
import { usePages } from "./hooks/usePages";
import DefaultToaster from "../Components/DefaultToaster";
import MainContainer from "../Components/MainContainer";

const MAX_WORDS = 100;

const Contact = () => {
  const {
    classes,
    handleSubmit,
    showToaster,
    setShowToaster,
    toasterMessage,
    isAgreed,
    handleCheckboxChange,
    navigate,
    isSmScreen,
  } = usePages();

  const renderBackButton = () => (
    <Box className={isSmScreen ? classes.circleStyleDark : classes.circleStyle} onClick={() => navigate("/")}>
      <ArrowBack  />
    </Box>
  );

  return (
    <MainContainer title={"Contact Us"}>
      <Grid container component="main" sx={{ height: "100vh", overflow: 'hidden' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: "url(https://source.unsplash.com/random?wallpapers)",
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light" ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          {!isSmScreen && renderBackButton()}
        </Grid>
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          {isSmScreen && renderBackButton()}

          <Box
            sx={{
              my: 2,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlined />
            </Avatar>
            <Typography component="h1" variant="h5">
              Contact Us
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <Grid container spacing={1} sx={{overflow: isSmScreen ? 'auto' : 'hidden', height: isSmScreen ? '65vh' : 'inherit'}} >
                <Grid item xs={12} sm={6} >
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="firstName"
                    label="First Name"
                    name="firstName"
                    autoComplete="given-name"
                    autoFocus
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="lastName"
                    label="Last Name"
                    name="lastName"
                    autoComplete="family-name"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="message"
                    label="Message (Max 100 words)"
                    name="message"
                    multiline
                    rows={4}
                    inputProps={{
                      maxLength: MAX_WORDS,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="caption" color="textSecondary">
                    {/* {`${wordCount}`} */}
                  </Typography>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value="allowExtraEmails"
                        color="primary"
                        checked={isAgreed}
                        onChange={handleCheckboxChange}
                      />
                    }
                    label={`By checking this box, I agree to receive email newsletters and updates from ${process.env.REACT_APP_WEBSITE_NAME} for the latest news, offers, and promotions.`}
                  />
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                disabled={!isAgreed}
              >
                Subscribe
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <DefaultToaster
        setShowToaster={setShowToaster}
        showToaster={showToaster}
        severity={toasterMessage?.severity as any}
        message={toasterMessage?.message}
      />
    </MainContainer>
  );
};

export default Contact;
