import React, { useState, useEffect } from 'react';
import routes from "./config/routes";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import useStyles from './styles/useStyles';
import PreLoader from './Components/PreLoader';
import logoVideo from './images/nw.gif';

const RouteContent: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const classes = useStyles();
  
  useEffect(() => {
    setLoading(true);
    const timer = setTimeout(() => setLoading(false), 3000);
    return () => clearTimeout(timer);
  }, [location]);

  return loading ? (
    <Box 
    sx={{ 
      display: 'flex', 
      flexDirection: 'column', 
      justifyContent: 'center', 
      alignItems: 'center', 
      height: '100vh',
      width: '100vw',
      position: 'fixed',  
      top: 0, 
      left: 0,
      zIndex: 1000,  
      backgroundColor: 'rgba(0, 0, 0, 0.5)'
    }}
  >
    {logoVideo ? <img className={classes.image} src={logoVideo} alt="NW"/> : <PreLoader />}
  </Box>
  ) : (
    <Routes>
      {routes?.map((route, index) => (
        <Route
          key={index}
          path={route.path}
          element={<route.component />}
        />
      ))}
    </Routes>
  );
};

const App: React.FC = () => {
  return (
    <BrowserRouter basename='/'>
      <RouteContent />
    </BrowserRouter>
  );
};

export default App;
