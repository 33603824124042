import { CircularProgress } from "@mui/material";
import { Box } from "@mui/system";

const DefaultLoader = () => {
    return (
        <Box 
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',    
                height: '50vh'          
            }}
        >
            <CircularProgress disableShrink />
        </Box>
    );
}

export default DefaultLoader;
