import { Box, Typography } from "@mui/material";
import MainContainer from "../Components/MainContainer";
import image from "../images/background.jpg";
import { usePages } from "./hooks/usePages";
import Countdown from "./components/Countdown";
import { Instagram } from "@mui/icons-material";
import WordAnimation from "../Components/WordAnimation";
import InstagramPosts from "./components/InstagramPosts";

const Home = () => {

  const { classes } = usePages()
  return (
    <MainContainer title={'Coming Soon'}>
      <Box className={classes.container}>
        <img className={classes.image} src={image} alt="Background" />
        <Box className={classes.bodyAfter} />
        <WordAnimation text="Coming Soon..." />
        <Countdown />
        {/* <Button onClick={() => navigate("/contact")}
              className={classes.button}
              variant="contained"
              color="primary">Contact Us</Button> */}
        <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography className={classes.paragraph} variant="caption">
            The darkest hour is just before the dawn
          </Typography>
          <a href="https://www.instagram.com/notoriouslywise/?hl=en" target="_blank" rel="noopener noreferrer">
            <Instagram style={{ color: 'white', cursor: 'pointer', marginTop: '2px' }} />
          </a>
        </Box>

             // <InstagramPosts />
      </Box>
    </MainContainer>
  );
};

export default Home;
