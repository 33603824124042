import { Box } from '@mui/system';
import React, { useState, useEffect } from 'react';
import DefaultCard from '../../Components/DefaultCard';
import { Grid } from '@mui/material';
import DefaultLoader from '../../Components/DefaultLoader';
import useStyles from '../../styles/useStyles';

const InstagramPosts: React.FC = () => {
  const classes = useStyles();

  const [posts, setPosts] = useState<any[]>([]);
  const instagramEndPoint = `https://graph.instagram.com/me/media?fields=id,caption,media_type,media_url,thumbnail_url,permalink&access_token=${process.env.REACT_APP_INSTAGRAM_ACCESS_TOKEN}`
  useEffect(() => {
    fetch(instagramEndPoint as string)
      .then(res => res.json())
      .then(data => {
        setPosts(data.data);
      });
  }, []);

  return (

    <>
      <Box className={classes.postContents}>
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          {!posts?.length ? <DefaultLoader /> : (
            posts?.map((post, index) => (
              <Grid item xs={16} md={4} key={index}>
                <DefaultCard
                  title={process.env.REACT_APP_WEBSITE_NAME}
                  image={
                    post.media_type === 'VIDEO' ? post.thumbnail_url : post.media_url
                  }
                  // maxWidth= {33}
                  instagramLink={post.permalink}
                  postUrl={post.media_url}
                  actions={[
                    { label: "Share" },
                    { label: "View" }
                  ]}
                />
              </Grid>
            ))


          )}

        </Grid>
      </Box>

    </>

  );
};

export default InstagramPosts;