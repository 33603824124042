// import ErrorPage from '../pages/Error'
import Home from '../pages/Home'
import Contact from '../pages/Contact';

interface RouteType {
  path: string;
  component: any;
  name: string;
  protected: boolean;
}


const routes: RouteType[] = [
//   {
//     path: "*",
//     component: ErrorPage,
//     name: "Error Page",
//     protected: false,
//   },
  {
    path: "/",
    component: Home,
    name: "Home",
    protected: true,
  },
  {
    path: "/contact",
    component: Contact,
    name: "Contact Us",
    protected: true,
  },
];

export default routes;