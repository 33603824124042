import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  '@global': {
    '*': {
      margin: 0,
      padding: 0,
      boxSizing: 'border-box',
      fontFamily: '"Poppins", sans-serif',
    },
  },

  circleStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 2,
    margin: '2px 0 0 2px',
    borderRadius: '50%',   // This makes the box circular
    backgroundColor: 'white', // Sets the background color to white
    width: '40px',         // Set the width of the circle
    height: '40px',        // Set the height of the circle
  },

  circleStyleDark: {
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 2,
    margin: '2px 0 0 2px',
    borderRadius: '50%',   // This makes the box circular
    backgroundColor: 'black', // Sets the background color to white
    width: '40px',         // Set the width of the circle
    height: '40px',        // Set the height of the circle
  },


  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
  },
  container: {
    display: 'flex',
    rowGap: '10px',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '100vh',
    width: '100%',
    overflow: 'hidden',
  },
  image: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    objectFit: 'cover',
    zIndex: -1,
  },
  bodyAfter: {
    position: 'absolute',
    content: '""',
    top: 0,
    height: '100%',
    width: '100%',
    background: '#000',
    zIndex: -1,
    opacity: 0.2,
  },
  header: {
    fontSize: '60px',
    color: '#fff',
    fontWeight: 600,
    textAlign: 'center',
  },
  paragraph: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#ffffff',
    maxWidth: '550px',
    textAlign: 'center',

  },
  postContents: {
    // display: 'block',
    columnGap: '30px',
    alignItems: 'center',
    // margin: '2rem',
    border: '1px solid #6b6b6b',
    padding: '.5rem',
    borderRadius: '0.5rem',
    background: 'rgba(250, 250, 250, 0.2)',
    height: '500px',
    width: '80%',
    overflowY: 'auto',
    // Hide scrollbar for Chrome, Safari and Opera
    '&::-webkit-scrollbar': {
        display: 'none',
    },
    // Hide scrollbar for Firefox
    scrollbarWidth: 'none',
    // Hide scrollbar for IE and Edge
    '-ms-overflow-style': 'none'
},

  timeContent: {
    display: 'flex',
    columnGap: '30px',
    alignItems: 'center',
    margin: '2rem',
    border: '1px solid #6b6b6b', 
    padding: '2rem 2.5rem',
    borderRadius: '0.5rem',
    background: 'rgba(250, 250, 250, 0.2)',
  },

  time: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  number: {
    fontWeight: 800,
    fontSize: '40px',
    lineHeight: 1,
    color: '#eee',
  },
  text: {
    textTransform: 'capitalize',
    color: '#fff',
    fontWeight: 600,
    fontSize: '8px',
  },
  emailContent: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
  },
  emailParagraph: {
    fontSize: '13px',
    color: '#fff'
  },
  inputBox: {
    display: 'flex',
    alignItems: 'center',
    height: '40px',
    width: '100%',
    maxWidth: '360px',
    marginTop: '20px',
    columnGap: '20px',
  },
  input: {
    height: '100%',
    outline: 'none',
    border: 'none',
    borderRadius: '4px',
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    fontWeight: 400,
    width: '100%',
    maxWidth: '100%', // Update the maxWidth to '100%'
    padding: '0 15px',
    color: '#eee',
    '&::placeholder': {
      color: '#fff',
    },
  },


  button: {
    cursor: 'pointer',
    backgroundColor: '#eee',
    color: '#0d6a81',
    whiteSpace: 'nowrap',
    padding: '0 20px',
    transition: 'all 0.3s ease',
    '&:hover': {
      backgroundColor: '#fff',
    },
  },

  paragraph2: {
    backgroundColor: '#2c2c2c',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.05)', 
    padding: 1, 
    borderRadius: 4, 
    color: '#ffffff'
  },
})

);

export default useStyles;