import { Card, CardMedia, CardContent, Typography, CardActions, Button, Menu, MenuItem } from "@mui/material";
import { createTheme } from "@mui/system";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ThemeProvider } from "styled-components";

interface DefaultCardProps {
  title?: string;
  subtitle?: string;
  image?: string;
  imageTitle?: string;
  actions?: { label: string }[];
  maxWidth?: number;
  postUrl?: string
  instagramLink?: string
}

const DefaultCard: React.FunctionComponent<DefaultCardProps> = ({
  title,
  subtitle,
  image,
  imageTitle,
  actions,
  maxWidth,
  postUrl,
  instagramLink,
}) => {
  const theme = createTheme({
    palette: {
      primary: {
        main: '#84739a', // your orange color
      },
      // other palette properties
    },
  });
  
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEmailShare = () => {
    const subject = encodeURIComponent("Discover Daily Wisdom 🌟! | Notoriously Wise 💡");
    const body = encodeURIComponent(`Discover Daily Wisdom: Unmissable Instagram Proverb Feed! 🌟: ${process.env.REACT_APP_INSTAGRAM_NAME}`);
    window.location.href = `mailto:?subject=${subject}&body=${body}`;
    handleClose();
  };

  const handleInstagramShare = () => {
    if (isMobileDevice()) {
      // Create a dynamic <a> tag to try and open the Instagram app
      const anchor = document.createElement('a');
      anchor.href = process.env.REACT_APP_INSTAGRAM_APP_DIRECT as string;
      anchor.click();
    } else {
      window.open(process.env.REACT_APP_INSTAGRAM_NAME, '_blank');
    }
    handleClose();
  };

  const handleWhatsAppShare = () => {
    const text = encodeURIComponent(`Discover Daily Wisdom: Unmissable Instagram Proverb Feed! 🌟: ${process.env.REACT_APP_INSTAGRAM_NAME}`);

    if (!isMobileDevice)  window.location.href = `https://api.whatsapp.com/send?text=${text}`; else {
        window.location.href = `whatsapp://send?text=${text}`;
      setTimeout(() => {
        window.location.href = `https://api.whatsapp.com/send?text=${text}`;
      }, 15000);
    }
  
    handleClose();
  };
  // A simple function to check if the user is on a mobile device
  const isMobileDevice = () => {
    return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
  };


  const handleViewClick = (type: string) => {

    switch (type) {
      case 'mediaClick':
        return window.open(postUrl, '_blank')
      case 'viewClick':
        return window.open(instagramLink, '_blank')
      default:
        navigate(0)
        break;
    }
  };

  return (
    <ThemeProvider theme={theme}>
    <Card sx={{ maxWidth, backgroundColor: '#1f1f1f', border: '2px solid #0a0a0a' }}>
      <CardMedia
        sx={{ height: 300, backgroundSize: "cover", cursor: 'pointer' }}
        image={image}
        title={imageTitle}
        onClick={() => handleViewClick('mediaClick')}
      />
      <CardContent>
        {/* <Typography gutterBottom variant="caption" component="div">
          {title}
        </Typography> */}
        <Typography variant="body2" color="text.secondary">
          {subtitle}
        </Typography>
      </CardContent>
      <CardActions style={{display: 'flex', justifyContent: 'center'}}>
        {actions && actions.map((action, index) => (
          <>
            <Button
              key={index} 
              size="small"
              onClick={(e) => (
                action?.label?.includes('View') ? handleViewClick('viewClick') : handleClick(e)
              )}
              variant="outlined"
              color="primary"
            >
              {action.label}
            </Button>


            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleEmailShare}>Share via Email</MenuItem>
              <MenuItem onClick={handleInstagramShare}>Share on Instagram</MenuItem>
              <MenuItem onClick={handleWhatsAppShare}>Share on WhatsApp</MenuItem>
            </Menu>

          </>

        ))}

      </CardActions>
    </Card>
    </ThemeProvider>
  );
}

export default DefaultCard;