import React, { useEffect } from "react";
import useStyles from "../styles/useStyles";
import { Box } from "@mui/material";

interface IMainContainerProps {
  children?: React.ReactNode;
  title: string;
  className?: string
}

const MainContainer: React.FunctionComponent<IMainContainerProps> = ({ children, title, className }) => {
  const classes = useStyles();

  useEffect(() => {
    document.title = `${title + ' | ' + process.env.REACT_APP_WEBSITE_NAME}` || process.env.REACT_APP_WEBSITE_NAME as string;
  }, [title]);

  return <Box className={`${classes.root} ${className}`} component="main" >{children}</Box>;
};

export default MainContainer;
